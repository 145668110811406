import GMCLogo from "../../Assets/logos/gmc.png";
import BaufiLogo from "../../Assets/logos/baufi.png";
import NewzooLogo from "../../Assets/logos/newzoo.png";
import DieKucheLogo from "../../Assets/logos/diekuche.png";
import DreenLogo from "../../Assets/logos/dreen.png";
import SmokelessLogo from "../../Assets/logos/smokeless.png";
import { LocateIcon, MapPin, Pin } from "lucide-react";

export type TechLogoType = {
  name: string;
  id: number;
  img: string;
};

export const techLogos: TechLogoType[] = [
  {
    name: "Python",
    id: 1,
    img: "https://149860134.v2.pressablecdn.com/wp-content/uploads/pythoned.png",
  },
  {
    name: "FastAPI",
    id: 2,
    img: "https://avatars.githubusercontent.com/u/156354296?s=280&v=4",
  },
  {
    name: "Langchain",
    id: 3,
    img: "https://opensource.muenchen.de/logo/langchain.jpg",
  },
  {
    name: "ElevenLabs",
    id: 4,
    img: "https://ubos.tech/wp-content/uploads/2024/01/ElevenLabs-Logo.png",
  },
  {
    name: "Claude",
    id: 5,
    img: "https://pub-4271c874f759418fbdcd18b0e5cbe024.r2.dev/Claude/claude-logo.png",
  },
  {
    name: "Metatrader",
    id: 6,
    img: "https://m.media-amazon.com/images/I/61unZtk4KEL.png",
  },
  {
    name: "Hugging Face",
    id: 7,
    img: "https://m.media-amazon.com/images/I/61unZtk4KEL.png",
  },
  {
    name: "Jira",
    id: 8,
    img: "https://cdn.worldvectorlogo.com/logos/jira-1.svg",
  },
  {
    name: "Discord",
    id: 9,
    img: "https://www.svgrepo.com/show/353655/discord-icon.svg",
  },
  {
    name: "Redux",
    id: 10,
    img: "https://cdn.worldvectorlogo.com/logos/redux.svg",
  },
  {
    name: "React",
    id: 11,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1150px-React-icon.svg.png",
  },
  {
    name: "OpenAI",
    id: 12,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS3PwERLLNB9XKFpeMgAMPxl5VvN3HRJnXQQ&s",
  },
  {
    name: "HubSpot",
    id: 13,
    img: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/168_Hubspot_logo_logos-512.png",
  },
  {
    name: "Tailwind",
    id: 14,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/1200px-Tailwind_CSS_Logo.svg.png",
  },
  {
    name: "Typescript",
    id: 15,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1200px-Typescript_logo_2020.svg.png",
  },
  {
    name: "Jotai",
    id: 16,
    img: "https://cdn.candycode.com/jotai/jotai-mascot.png",
  },
  {
    name: "Storybook",
    id: 17,
    img: "https://www.svgrepo.com/show/354397/storybook-icon.svg",
  },
  {
    name: "Wordpress",
    id: 18,
    img: "https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_wordpress-512.png",
  },
  {
    name: "Shopify",
    id: 19,
    img: "https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png",
  },
  {
    name: "WooCommerce",
    id: 20,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/WooCommerce_logo.svg/2560px-WooCommerce_logo.svg.png",
  },
  {
    name: "Node.js",
    id: 21,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png",
  },
  {
    name: "Slack",
    id: 22,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/2048px-Slack_icon_2019.svg.png",
  },
  {
    name: "Lottie",
    id: 23,
    img: "https://cdn.iconscout.com/icon/free/png-256/free-lottiefiles-logo-icon-download-in-svg-png-gif-file-formats--lottifiles-brand-iconscout-pack-logos-icons-4674917.png",
  },
  {
    name: "Gitlab",
    id: 24,
    img: "https://static-00.iconduck.com/assets.00/gitlab-icon-1024x942-f30d1qro.png",
  },
  {
    name: "SCSS",
    id: 25,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sass_Logo_Color.svg/1280px-Sass_Logo_Color.svg.png",
  },
  {
    name: "Javascript",
    id: 26,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-aFzLDiRodJ8Wyve_gVCByj6i3-wDtK9pNQ&s",
  },
  {
    name: "Github",
    id: 27,
    img: "https://cdn.pixabay.com/photo/2022/01/30/13/33/github-6980894_640.png",
  },
  {
    name: "React Native",
    id: 28,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1150px-React-icon.svg.png",
  },
];

const timelineContent = ({
  title,
  description,
  items,
  company,
  companyLogo,
  location,
}: {
  title: string;
  description: string;
  items: string[];
  company?: string;
  companyLogo?: string;
  location?: string;
}) => {
  // Utility function with minimal TypeScript typing
  const getImageSource = (item: string): string => {
    return techLogos.find((tech) => tech.name === item)?.img || "";
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h2 className="heading-2">{title}</h2>
        <div className="flex flex-wrap items-center gap-4 bg-stroke/70 w-fit px-2 py-1 rounded-md shadow-sm shadow-accent/50 text-white/70">
          {company ? (
            <div className="flex items-center gap-2">
              {companyLogo && (
                <img src={companyLogo} className="h-6 w-6 rounded-md " />
              )}
              <p className="heading-4">{company}</p>
            </div>
          ) : null}
          {location && (
            <div className="flex gap-1 items-center">
              <MapPin strokeWidth={2} height={22} />
              <p>{location}</p>
            </div>
          )}
        </div>
      </div>
      <p>{description}</p>
      <div className="flex gap-4 flex-wrap items-center">
        {items.map((item, i) => (
          <div className="flex items-center px-2 md:px-4 py-1 gap-2 bg-stroke text-white p-2 shadow shadow-accent/30 rounded-full">
            <img
              key={i}
              src={getImageSource(item)}
              alt={`${item} icon`}
              className="h-6 w-6 object-contain "
            />
            <p className="body-1">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const timelineExperienceData = [
  {
    title: "AI Hospitality Voice Assistant",
    content: timelineContent({
      location: "Tenerife, Spain",
      title: "Multilingual Hotel Voice Assistant",
      description:
        "Built to streamline customer engagement, our intelligent voice agent system effortlessly handles calls—scheduling appointments, addressing inquiries, and integrating with databases in real time. With multilingual capabilities, it boosts efficiency and enhances service delivery while reducing operational demands on staff.",
      items: ["Python", "FastAPI", "Langchain", "ElevenLabs", "Claude"],
    }),
  },
  {
    title: "Automated Trading System",
    content: timelineContent({
      location: "Spain",
      title: "AI-Powered Forex Trading Algorithm",
      description:
        "Created to empower traders, our sophisticated 24/7 trading algorithm for MetaTrader autonomously evaluates market indicators and executes trades based on tailored strategies. Leveraging artificial intelligence, it optimizes decision-making, delivering a fully automated solution that enhances trading precision and efficiency.",
      items: ["Metatrader", "Python", "Jira", "Hugging Face", "Discord"],
    }),
  },
  {
    title: "Financial Market Analytics Platform",
    content: timelineContent({
      title: "Market Data Visualization & AI Reports",
      company: "GlobalMarketCaps",
      location: "Switzerland",
      companyLogo: GMCLogo,
      description:
        "Played a key role in building a comprehensive financial market platform, designed to provide retail traders with real-time market data visualizations and AI-generated reports derived from earnings calls and financial documents. This initiative enabled traders to access professional-grade insights with greater efficiency and precision.",
      items: ["Redux", "React", "Langchain", "Python", "OpenAI"],
    }),
  },
  {
    title: "Kitchen Design Configurator",
    content: timelineContent({
      title: "Interactive Kitchen Configuration System",
      company: "DieKueche",
      location: "Germany",
      companyLogo: DieKucheLogo,
      description:
        "Contributed to crafting a sophisticated server-side React form as configuration system, built to empower end-users in designing custom kitchens through modular components. This solution captured precise appliance and furnishing requirements, integrated with Hubspot to generate qualified leads, and greatly streamlined the sales process.",
      items: ["React", "HubSpot", "Tailwind", "Typescript", "Jotai"],
    }),
  },
  {
    title: "Mortgage Calculator Suite",
    content: timelineContent({
      title: "Advanced Real Estate Financial Calculators",
      company: "Baufi24",
      location: "Germany",
      companyLogo: BaufiLogo,
      description:
        "Led the creation of a comprehensive suite of six interactive financial calculators, designed to leverage real-time regional data across Germany for complex calculations optimizing rates and financial options. Tailored to diverse customer scenarios, this solution integrated responsive design, advanced financial modeling, investment analysis, loan optimization, and lead generation capabilities, empowering thousands of daily users on an industry-leading real estate platform.",
      items: ["React", "Typescript", "Tailwind", "Storybook", "HubSpot"],
    }),
  },
  {
    title: "E-Commerce Platform",
    content: timelineContent({
      title: "Custom WordPress E-Commerce Solution",
      company: "Dreen",
      location: "Valencia, Spain",
      companyLogo: DreenLogo,
      description:
        "Spearheaded the development of a feature-rich WooCommerce store, crafted to streamline e-commerce with integrated payment gateways and multi-carrier shipping solutions. This project encompassed custom template development, specialized product variant handling, robust content delivery systems, and automated email marketing functionality, driving enhanced customer engagement and operational efficiency.",
      items: ["Wordpress", "Shopify", "WooCommerce", "Node.js", "Slack"],
    }),
  },
  {
    title: "Cross-Platform Health Application",
    content: timelineContent({
      title: "Smoking Cessation Mobile & Web Application",
      company: "Smokeless",
      location: "Germany",
      companyLogo: SmokelessLogo,
      description:
        "Drove the frontend development of a comprehensive health tracking ecosystem, designed to empower users across iOS, Android, and web platforms using React Native and React. This solution featured seamless hardware device integration, intuitive progress monitoring, personalized exercise programs, engaging social networking capabilities, and a real-time messaging system, enhancing user support throughout their smoking cessation journey",
      items: ["React", "React Native", "Typescript", "Lottie", "Gitlab"],
    }),
  },
  {
    title: "Gaming Analytics Dashboard",
    content: timelineContent({
      title: "Interactive Gaming Data Visualization Platform",
      company: "Newzoo",
      location: "The Netherlands",
      companyLogo: NewzooLogo,
      description:
        "Crafted sophisticated frontend components for an industry-leading gaming analytics platform, built to deliver complex gaming data through interactive visualizations, comparative tables, and dynamic ranking systems. This solution empowered clients to uncover actionable insights from massive datasets with an intuitive, responsive interface designed for seamless engagement.",
      items: ["React", "SCSS", "Javascript", "Redux", "Github"],
    }),
  },
];
