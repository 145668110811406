import { HeroSection } from "../../modules/HeroSection/HeroSection";
import {
  AIAndAutomationSection,
  ConsultancySection,
  CustomSolutionsSection,
  WebDevelopmentSection,
} from "./ServiceSectionsData";
import { Header } from "../../modules/Header/Header";
import { ThreeDMarqueeSection } from "../../Components/CardsMarquee/CardsMarquee";
import { Footer } from "../../modules/Footer/Footer";
import { BentoGridDemo } from "../../Components/EffectCards/EffectCards";
import { NumberCard } from "../../Components/NumberCard/NumberCard";
import { useTranslate } from "../../hooks/useTranslation";
import { impactData } from "./ImpactCardsData";
import cn from "classnames";
import { TextRevealByWord } from "../../Components/TextReveal/TextReveal";
import { TimelineDemo } from "../../Components/Timeline/TimelineDemo";
import { Timeline } from "../../Components/Timeline/Timeline";
import { techLogos, timelineExperienceData } from "./TimelineExperienceData";
import { WavyBackground } from "../../Components/WaveBackground/WaveBackground";
import { FadeCarousel } from "../../Components/FadeCarousel/FadeCarousel";

export const LandingPage = () => {
  return (
    <div className="">
      <Header />
      <HeroSection />
      <div className=" absolute h-[150px] -mt-[150px] w-full bg-gradient-to-b from-transparent to-background w-full" />
      <div className="min-h-screen flex justify-center items-center">
        <div className="grid grid-cols-12 px-2 gap-4 md:gap-8 max-w-[100em] mx-auto">
          {impactData.map((dataFact, i) => {
            return (
              <NumberCard
                key={i}
                number={dataFact.number}
                symbol={dataFact.symbol}
                text={dataFact.text}
                img={dataFact.img}
                title={dataFact.title}
                className={cn(
                  "h-full col-span-12 sm:col-span-6 lg:col-span-4",
                  i === 0 && "!col-span-12 lg:!col-span-4 lg:row-span-2",
                  i === 3 && "!col-span-12 lg:!col-span-8"
                )}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col relative mb-40">
        <AIAndAutomationSection />
        <WebDevelopmentSection />
        <CustomSolutionsSection />
        <ConsultancySection />
      </div>
      <TextRevealByWord text="Innovation flows like a river, ever-changing. To stay still is to be left behind." />
      <div className="relative overflow-x-hidden">
        <WavyBackground className="mt-20 mb-20">
          <h2 className="heading-1 font-medium">Our Adventures</h2>
        </WavyBackground>
      </div>
      <div className="-mt-40">
        <Timeline data={timelineExperienceData} />
      </div>

      <div className="h-screen flex items-center justify-center py-24">
        <div className="mx-auto flex w-full max-w-screen-lg flex-col items-center space-y-8">
          <div className="text-center flex flex-col gap-2">
            <h2 className="heading-2">No project is too ambitious</h2>
            <h3 className="heading-3">No tech stack we can't cover</h3>
          </div>

          <FadeCarousel columnCount={3} logos={techLogos} />
        </div>
      </div>

      {/* <BentoGridDemo /> */}
      <Footer />
    </div>
  );
};
